<template>
  <v-col :cols="size">
    <v-row justify="center">
      <v-toolbar flat color="transparent">
        <div>
          <h2 class="mb-2">
            {{ title }}
          </h2>
          <span v-if="subtitle" class="grey--text">
            افزودن قیمت و موجودی برای متغیر
            {{ subtitle }}
          </span>
        </div>
        <v-spacer></v-spacer>
        <v-chip
          class="ma-2"
          close
          close-icon="mdi-plus-circle"
          color="primary"
          text-color="white"
          v-if="addRecordActive"
          @click="addRecords"
        >
          افزودن
        </v-chip>
      </v-toolbar>
    </v-row>
    <draggable v-model="records" v-if="draggable">
      <v-row v-for="(item, i) in records" :key="'multiinput-' + item">
        <!-- <v-icon>mdi-swap-vertical</v-icon> -->
        <v-col cols="12" class="pt-0 pb-0 mt-0 mb-0">
          <v-row>
            <v-col cols="11">
              <v-form-base :model="item" :schema="schema" :row="rowAttribute" />
            </v-col>

            <v-col cols="1" v-if="removeRecordActive" class="mt-7">
              <v-btn icon outlined @click="removeRecords(i)" color="error">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />
        </v-col>
      </v-row>
    </draggable>
    <v-row v-else>
      <v-col
        v-for="(item, i) in records"
        :key="'multiinput-' + item"
        cols="12"
        class="pt-0 pb-0 mt-0 mb-0"
      >
        <v-row>
          <v-col cols="11">
            <v-form-base :model="item" :schema="schema" :row="rowAttribute" />
          </v-col>

          <v-col cols="1" v-if="removeRecordActive" class="mt-7">
            <v-btn icon outlined @click="removeRecords(i)" color="error">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import VFormBase from "vuetify-form-base";
import draggable from "vuedraggable";
export default {
  components: {
    VFormBase,
    draggable,
  },
  data() {
    return {
      rowAttribute: {
        justify: "right",
        align: "right",
        noGutters: true,
      },
    };
  },
  props: {
    value: {
      // v-model instance
      type: Array,
      required: true,
    },
    title: {
      type: String,
    },
    size: {
      type: String,
      default: 12,
    },
    subtitle: {
      type: String,
      default: null,
    },
    addRecordActive: {
      type: Boolean,
      default: true,
    },
    removeRecordActive: {
      type: Boolean,
      default: true,
    },
    pushRecord: {
      type: Object,
    },
    schema: {
      type: Object,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    addRecords() {
      let record = {};
      Object.keys(this.schema).forEach((key) => {
        record[key] = "";
      });
      this.records.push(record);
    },
    removeRecords(index) {
      this.records.splice(index, 1);
    },
    getRecords() {
      return this.records;
    },
    onEditClick(item) {
      this.$emit("onEditClick", item);
    },
    onDeleteClick(item) {
      this.$emit("onDeleteClick", item);
    },
  },
  mounted() {
    this.addRecords();
  },
  computed: {
    records: {
      get() {
        return this.value;
      },
      set(records) {
        this.$emit("input", records);
      },
    },
  },
  watch: {
    value() {
      console.log("model", this.value);
    },
  },
};
</script>

<style></style>
